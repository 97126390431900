import React from 'react'

export const Header = () => {
    return(
        <section className="mb-32 text-gray-800 text-center lg:text-left">

        <div className="grid lg:grid-cols-2 gap-6 xl:gap-12 items-center">
          <div className="mb-6 lg:mb-0">
            <h2 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight dark:text-neutral-300 dark:hover:text-neutral-200 dark:focus:bg-neutral-200">
              What is "Summarize"<br />
              <span className="text-cyan-600 ">How does it help me?</span>
            </h2>
          </div>
          <div className="mb-6 lg:mb-0">
            <p className="uppercase text-cyan-600 font-bold mb-4">Summarize is a next <b>*gen*</b> word processor which lets you go fazzt ⏩</p>
            <p className="text-gray-500 dark:text-neutral-300 dark:hover:text-neutral-200 dark:focus:bg-neutral-200 text-lg">
              While the whole <b className='text-neutral-50'>"Next Gen"</b> thing might sound a little buzz wordy but there exists nothing to compare Summarize to.
              This is just a different type of word processor / knowledge-base which hasn't existed till now. Summarize lets you  <span className='dark:text-cyan-300 text-cyan-700'>write quicker</span>
              , <span className='dark:text-cyan-300 text-cyan-700'>organize/search faster</span> and also <span className='dark:text-cyan-300 text-cyan-700 font-bold'>summarize</span>
              <i className='font-extralight'> (as you can tell from the name)</i> web content or documents.  
            </p>
          </div>
        </div>
  
      </section>
    )
}