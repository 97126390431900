import logoLight from "../images/SummarizeLight.svg";
import logoDark from "../images/SummarizeDark.svg";

const DARK_THEME = "dark"
import React, { Component } from 'react'

type NavState ={
    theme: boolean
}

export default class nav extends Component<{}, NavState> {

    render() {
        return (
            <nav className="navbar navbar-expand-lg shadow-md py-2 bg-white dark:bg-neutral-800 relative flex items-center w-full justify-between">
                <div className="px-6 w-full flex flex-wrap items-center justify-between">
                    <div className="flex items-center">
                        <button
                            className="navbar-toggler border-0 py-3 lg:hidden leading-none text-xl bg-transparent text-gray-600 hover:text-gray-700 focus:text-gray-700 transition-shadow duration-150 ease-in-out mr-2"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContentY"
                            aria-controls="navbarSupportedContentY"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                className="w-5"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                                ></path>
                            </svg>
                        </button>
                        <a className="navbar-brand text-blue-600" href="#!">
                        <picture>
                            <source srcSet={logoLight} media="(prefers-color-scheme: dark)"/>
                            <img src={logoDark} alt="Animated wave shape" className="w-8"/>
                        </picture>
                        </a>
                    </div>
                    <div className="navbar-collapse collapse grow items-center" id="navbarSupportedContentY">
                        <ul className="navbar-nav mr-auto lg:flex lg:flex-row">
                            <li className="nav-item">
                                <a className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 dark:text-neutral-300 dark:hover:text-neutral-200 dark:focus:bg-neutral-200 transition duration-150 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">Dashboard</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 dark:text-neutral-300 dark:hover:text-neutral-200 dark:focus:bg-neutral-200 transition duration-150 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">Team</a>
                            </li>
                            <li className="nav-item mb-2 lg:mb-0">
                                <a className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 dark:text-neutral-300 dark:hover:text-neutral-200 dark:focus:bg-neutral-200 transition duration-150 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">Projects</a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex items-center lg:ml-auto dark:text-neutral-300 dark:hover:text-neutral-200 dark:focus:bg-neutral-200">
                        Website In <span className="text-cyan-400 ml-1"> Development</span>
                    </div>
                </div>
            </nav>
        )
    }
}
