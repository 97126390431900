import React from "react"


export const FeatureList = ()=>{
    return (
        <section className="mb-32 text-gray-800 dark:text-gray-200">
        <div className="block rounded-lg shadow-lg bg-white dark:bg-neutral-800">
          <div className="flex flex-wrap items-center">
            <div className="grow-0 shrink-0 basis-auto block w-full lg:flex lg:w-6/12 xl:w-4/12">
              <img src="https://mdbootstrap.com/img/new/ecommerce/vertical/012.jpg" alt="Trendy Pants and Shoes"
                className="w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg" />
            </div>
            <div className="grow-0 shrink-0 basis-auto w-full lg:w-6/12 xl:w-8/12">
              <div className="px-6 py-12 md:px-12">
                <h2 className="text-3xl font-bold mb-6">What makes it different ?</h2>
                <p className="text-gray-500 dark:text-neutral-300 mb-6">
                  Summarize's local <span className='dark:text-cyan-300 text-cyan-700'>ML</span> engine provides abilities which weren't possible before by any other editor.
                  All of these feautres while being able to use your idle* GPU for offloading the computation making text editing a responsive and private experience.   
                </p>
    
                <div className="grid md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-x-6">
                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg>Word Prediction
                    </p>
                  </div>
    
                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Sentence Prediction
                    </p>
                  </div>
    
                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Paragraph Genration
                    </p>
                  </div>
                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Rich editing
                    </p>
                  </div>
    
                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg>Markdown Support
                    </p>
                  </div>
    
                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Automatic Document Categories
                    </p>
                  </div>
                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Automatic Document tagging
                    </p>
                  </div>
    
                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Blazing fast search
                    </p>
                  </div>
    
                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Automatic Document Backlinks
                    </p>
                  </div>

                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Summary Generation
                    </p>
                  </div>

                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Related Content Summary/ Suggestion
                    </p>
                  </div>

                  <div className="mb-6">
                    <p className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-gray-900 dark:text-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg> Document Entity Recognition
                    </p>
                  </div>
                </div>
              </div>
                <p>
                  <span className="font-black text-xl text-cyan-100 uppercase bg-gray-300 dark:bg-black p-2 m-8 rounded-lg">And much much more ... </span>
                </p>
            </div>
          </div>
        </div>
      </section>
    )
}