import React from "react";

import CodeImage from "../images/code.jpg"

export const AboutML = () => {
    return (
        <section className="mb-32 text-gray-800 dark:text-neutral-100" id="info">
            <div className="container mx-auto xl:px-32 text-center lg:text-left">
                <div className="grid lg:grid-cols-2 flex items-center">
                    <div className="mb-12 lg:mb-0">
                        <div className="block rounded-lg shadow-lg px-6 py-12 md:px-12 lg:-mr-14 backdrop-blur-2xl bg-white dark:bg-neutral-600 bg-opacity-20 dark:bg-opacity-20">
                            <h2 className="text-3xl font-bold mb-6">How does Summarize built?</h2>
                            <p className="text-gray-500 dark:text-gray-400 mb-6 pb-2 lg:pb-0">
                                Summarize desktop is built on top of its ML engine <i>(which basically manages all the models and ML inference locally)</i> built in 
                                <a href="https://www.rust-lang.org/" className="text-cyan-600"> Rust</a>. Some of its base stack is :
                            </p>

                            <div className="flex flex-col md:flex-row md:justify-around lg:justify-between mb-6 mx-auto">
                                <p className="flex items-center mb-4 md:mb-2 lg:mb-0 mx-auto md:mx-0">
                                    - Tauri
                                </p>

                                <p className="flex items-center mb-4 md:mb-2 lg:mb-0 mx-auto md:mx-0">
                                    - Tensorflow / Pytorch
                                </p>

                                <p className="flex items-center mb-2 lg:mb-0 mx-auto md:mx-0">
                                    - Solid js
                                </p>
                            </div>

                            <p className="text-gray-500 dark:text-gray-400 mb-0">
                                Most of the team's time is spent on getting ML models made in tensorflow and pytorch fine tuned for 
                                out own use case, just right. Summarize will ship with multiple models which specialize in one perticular task or multiple based on implementation.
                                The long list of feautres available in summarie is possible due to managing of GPU and system memory.
                            </p>
                            <br/>
                            <p className="text-gray-500 dark:text-gray-400 mb-0">
                                Previously these types of models were only accessible on the server side as running them on <b>edge</b> is a big hassle plus has performance
                                implications. In recent years due to rise in GPU avaiblility in Desktop as well as mobile devices, we are now able to move this to client side.
                                Also, worry not, for those who don't want to use precious battery life in laptops running dedicated GPU or doesn't have access to one, we plan on releasing
                                server version.
                            </p>
                        </div>
                    </div>

                    <div>
                        <img src={CodeImage} className="rounded-lg shadow-xl dark:shadow-neutral-800"
                            alt="" />
                    </div>
                </div>
            </div>
        </section >
    );
}