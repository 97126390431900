import * as React from "react"
import Nav from "./nav";
import Summarize from "../images/Screenshot 2022-11-09 011805.png"


export const HeroSection = () => {
    return (
    <section className="mb-40" id="hero">
        <Nav/>
        <div className="px-6 py-12 md:px-12 text-gray-800 text-center lg:text-left">
            <div className="container mx-auto xl:px-32">
                <div className="grid lg:grid-cols-2 flex items-center">
                    <div className="md:mt-12 lg:mt-0 mb-12 lg:mb-0">
                        <div className="block rounded-lg shadow-lg px-6 py-12 md:px-12 lg:-mr-14 backdrop-blur-2xl bg-white dark:bg-neutral-600 bg-opacity-20 dark:bg-opacity-20">
                            <h1 className="text-4xl md:text-5xl xl:text-6xl font-bold tracking-tight mb-12 dark:text-neutral-300 dark:hover:text-neutral-200 dark:focus:bg-neutral-200">Summarize your <br /><span className="text-cyan-600">writing experience</span></h1>
                            <a className="inline-block px-7 py-3 mb-2 md:mb-0 md:mr-2 bg-cyan-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 transition duration-150 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="light">Comming soon</a>
                            <a className="inline-block px-7 py-3 bg-transparent text-cyan-600 font-medium text-sm leading-snug uppercase rounded hover:text-cyan-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 transition duration-150 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="light" href="#info" role="button">Learn more</a>
                        </div>
                    </div>
                    <div className="md:mb-12 lg:mb-0">
                        <img
                            src={Summarize}
                            className="w-full rounded-lg shadow-lg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>);
}