import React from "react";
import textImage from "./../images/text.jpg"

export const Stats = () => {
  return (
    <section className="mb-32 text-gray-800 dark:text-gray-100 text-center lg:text-left">

      <div className="container mx-auto xl:px-32 text-center lg:text-left">
        <div className="grid lg:grid-cols-2 flex items-center">
          <div className="mb-12 lg:mb-0">
            <div
              className="relative block rounded-lg shadow-lg px-6 py-12 md:px-12 lg:-mr-14 backdrop-blur-2xl z-[1] bg-white dark:bg-neutral-600 bg-opacity-20 dark:bg-opacity-20"
            >
              <h2 className="text-3xl font-bold mb-4 display-5"> <span className='dark:text-cyan-300 text-cyan-700 bg-gray-400 dark:bg-black rounded-xl mr-2 p-2'
              >Okay</span>, but why is it so great?</h2>
              <p className="text-gray-500 dark:text-gray-400 mb-12">
                Lets have some solid data about some stats that we have found so far as per general purpose usage of average people and how much summarize would help.
              </p>

              <div className="grid md:grid-cols-3 gap-x-6">
                <div className="mb-12 md:mb-0">
                  <h2 className="text-3xl font-bold text-dark mb-4">30-50%</h2>
                  <h5 className="text-lg font-medium text-gray-500 dark:text-gray-400 mb-0 wrap">Time saved while writing</h5>
                </div>

                <div className="mb-12 md:mb-0">
                  <h2 className="text-3xl font-bold text-dark mb-4">5-12%</h2>
                  <h5 className="text-lg font-medium text-gray-500 dark:text-gray-400 mb-0">Less time Searching</h5>
                </div>

                <div className="">
                  <h2 className="text-3xl font-bold text-dark mb-4 dark:text-cyan-300 ">Upto 75%</h2>
                  <h5 className="text-lg font-medium text-gray-500 dark:text-gray-400 mb-0">Time saved reading if summarized</h5>
                </div>
              </div>
            </div>
          </div>

          <div>
            <img
              src={textImage}
              className="w-5/6 shadow-lg  rotate-6"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}