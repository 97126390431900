import * as React from "react"
import type { HeadFC } from "gatsby"
import { HeroSection } from "../components/hero"
import { Header } from "../components/header"
import { FeatureList } from "../components/FeatureList"
import { Stats } from "../components/Stats"
import { AboutML } from "../components/About"
import { NewsLetter } from "../components/NewsLetter"


const IndexPage = () => {
  return (
    <main className="container mx-auto dark:bg-black">
      <HeroSection/>
      <Header/>
      <FeatureList/>
      <Stats/>
      <AboutML/>
      <NewsLetter/>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>
